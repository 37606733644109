.paperWrap {
  height: 100%;
  width: 100%;
  box-shadow: 3px 3px 5px 5px grey;
  position: relative;
  color: black;
}

.paperWrap::before {
  content: '';
  position: absolute;
  left: 45px;
  height: 100%;
  z-index: 1;
  width: 2px;
  background: rgba(255, 0, 0, 0.4);
}

.directions {
  font-size: 1.25rem;
  color: black;
}

.fireWrap {
  display: flex;
  justify-content: center;
}

.shredding {
  position: relative;
}

.shedPaperWrap {
  padding: 1rem;
  position: relative;
}

//shredder animation
.paperHeader {
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  > h3 {
    font-size: 1rem;
  }
}

.shredButton {
  font-family: sans-serif;
  background: transparent;
  align-self: end;
  display: flex;
  border: none;
  align-items: center;
  color: #ef3340;
  text-decoration: underline;
  text-underline-offset: 2px;
  font-weight: bold;
  > span {
    font-size: 1.5rem;
  }
}

.dbuttonwrap {
  display: flex;
  flex: 0;
}

.resetButton {
  font-size: 1.25rem;
}

.lineWrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #f1e2a9;
}

.lines {
  z-index: 0;
  position: absolute;
  height: calc(100% - 40px);
  bottom: 0;
  width: 100%;
  background-image: repeating-linear-gradient(
    #f1dfa1 0px,
    #f1dfa1 24px,
    steelblue 25px
  );
}

.textGenStyles {
  position: absolute;
  left: 55px;
  right: 10px;
  top: 0px;
  bottom: 10px;
  display: flex;
  flex-direction: column;
  > div {
    flex: 1;
    outline: none;
  }
  > textarea {
    flex: 1;
    outline: none;
    background: transparent;
    border: none;
    resize: none;
  }
  * {
    font-family: 'Indie Flower';
    overflow: hidden;
  }
  > * {
    padding: 0;
    margin: 0;
    font-size: 1rem;
    line-height: 25px;
    font-weight: 500;
  }
  h3 {
    font-weight: 920;
  }
}

.holes {
  z-index: 1;
  position: absolute;
  left: 10px;
  height: 25px;
  width: 25px;
  background: white;
  border-radius: 50%;
  box-shadow: inset 0px 0px 2px 0px #888;
}
.holeTop {
  top: 10%;
}
.holeMiddle {
  top: 50%;
}
.holeBottom {
  bottom: 10%;
}
