@import '_variables';

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

.speech-bubble {
  height: 100%;
  border: black solid 2px;
  z-index: 10;
  position: relative;
  background: rgb(231 231 231);
  border-radius: 0.4em;
  width: 100%;
  bottom: 50%;
  height: 30%;
  padding: 10px;
  color: black;
  z-index: 2;

  &.correct,
  &.incorrect {
    @include keyframes(jiggle) {
      0% {
        transform: rotate(0deg);
      }
      33% {
        transform: rotate(-5deg);
      }
      66% {
        transform: rotate(5deg);
      }

      100% {
        transform: rotate(0deg);
      }
    }
    @include animate(jiggle, 0.5s, linear, 1);
  }

  &.correct {
    border-color: $correctColor;
    color: black;
    .speech-text::before {
      border-block-color: $correctColor;
    }
  }

  &.incorrect {
    border-color: red;
    color: black;
    .speech-text::before {
      border-block-color: red;
    }
  }
}
.arrow {
  display: none;
}

.speech-bubble-shadow {
  display: none;
}

.speech-bubble-shadow:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 40%;
  width: 0;
  height: 0;
  border: 2.094em solid transparent;
  border-top-color: black;
  border-bottom: 0;
  border-left: 0;
  margin-left: -1.047em;
  margin-bottom: -2.094em;
}

// copy here //////////////////////////

.speech-bubble-modal {
  position: absolute;
  background: $speechBubble;
  border-radius: 0.4em;
  width: 40%;
  bottom: 50%;
  left: 12%;
  height: 30%;
  padding: 10px;
  color: black;
  // font-size: 2 vh;
  z-index: 2;
  //    overflow: hidden;
}
.arrow {
  display: none;
}

.speech-bubble-modal:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 40%;
  width: 0;
  height: 0;
  border-right: 0;
  margin-left: -1.047em;
  margin-bottom: -2.094em;
  overflow: hidden;
  border: 2.094em solid transparent;
  border-top-color: $speechBubble;
  border-bottom: 0;
  border-left: 0;
}

.speech-bubble-shadow-modal {
  position: absolute;
  background: black;
  border-radius: 0.4em;
  width: 40%;
  bottom: 49%;
  left: 13%;
  height: 30%;
  padding: 10px;
  color: black;
  // font-size: 20pt;
  z-index: 0;
}

.speech-bubble-shadow-modal:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 40%;
  width: 0;
  height: 0;
  border: 2.094em solid transparent;
  border-top-color: black;
  border-bottom: 0;
  border-left: 0;
  margin-left: -1.047em;
  margin-bottom: -2.094em;
}

// copy end ////////////////////////////////////////
.mainNavBubble {
  margin-top: 2rem;
  position: relative;
  width: 75%;
  max-width: 250px;
  left: 35%;
  bottom: 23px;
}
// div.mainNavBubble{
// 	z-index:100
// }

.speech-text {
  padding: 0.3em;
  height: 100%;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  p {
    margin: 0;
    color: black;
  }
}

#correctAnswer {
  opacity: 0;
}

#incorrectAnswer {
  opacity: 0;
  z-index: 1;
}
// @media screen and (max-height: 450px){
// 	.speech-text{
// 		font-size: .8em;
// 	}
// }
// @media screen and( max-width: 900px){
// 	.speech-text{
// 		font-size: .9em;
// 	}
// }

.speech-text:before {
  z-index: -1;
  left: 79px;
  bottom: -33px;
  right: 0;
  content: '';
  position: absolute;
  /* top: 0; */
  right: -14px;
  width: 0;
  /* height: 0; */
  border-right: 0;
  /* margin-left: -1.047em; */
  /* margin-bottom: -2.094em; */
  /* overflow: hidden; */
  border: 33px solid transparent;
  border-top-color: #000000;
  border-bottom: 0;
  border-left: 0;
}

.speech-text:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  border: 31px solid transparent;
  border-top-color: #e7e7e7;
  border-bottom: 0;
  border-left: 0;
  left: 81px;
  bottom: -30px;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;

  &.split-even {
    gap: 2rem;
  }
}
