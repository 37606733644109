@import '../../../stylesheets/variables';

.outerWrapper {
  background: #ecf0ed;
  padding: 1.5rem;
  border-radius: 10px;
  line-height: initial;
  @media screen and (max-width: 750px) {
    padding: 0.5rem;
  }
}

.finishedWrapper {
  padding: 1rem 1.5rem;
  background: #f59c52;
  background: linear-gradient(-45deg, #f59c52 6%, #3e85d0 61%);
  border-radius: 10px;
  line-height: initial;
  @media screen and (max-width: 750px) {
    padding: 0.5rem;
  }
}

.innerWrap {
  background: white;
  border-radius: 10px;
  padding: 1rem;
  @media screen and (max-width: 750px) {
    padding: 0.5rem;
  }
  color: black;
}

.grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr auto;
}

.bullyingForm {
  @extend .grid;

  p,
  span,
  label,
  legend {
    font-size: 1.25rem;
    margin: 0;
    padding: 0;
    @media screen and (max-width: 750px) {
      font-size: 1rem;
    }
  }

  fieldset:nth-child(odd) {
    // background: grey;
  }
}

.headerGrid {
  @extend .bullyingForm;
  border-radius: 0;
}

.trueOrFalse {
  @extend .bullyingForm;
}

.trueOrFalseHeader {
  @extend .trueOrFalse;
  margin-bottom: 1.5rem;
}

.dropdown {
  @extend .bullyingForm;
  align-items: start;
  font-size: 1rem;
  //   grid-template-columns: minmax(125px, 1fr) minmax(50px, 200px);
  grid-template-columns: 1fr 60px;
  > select {
    flex: 1;
    min-width: 4rem;
    max-width: fit-content;
  }
  @media screen and (min-width: 500px) {
    grid-template-columns: 1fr 150px;
  }
}

.dropdownHeader {
  grid-template-columns: 1fr;
  margin-bottom: 1.5rem;
}

.inputStyles {
  justify-self: center;
  width: 1.25rem;
  height: 1.25rem;

  &:disabled {
    accent-color: black;
  }
}

.wrapGrid {
  display: grid;
  grid-template-columns: 1fr repeat(2, 2rem);
  gap: 1rem;
}

.headyW {
  padding-bottom: 0.75rem;
  border-bottom: 2px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .index {
    white-space: nowrap;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .allCorrect {
      color: green;
    }
  }
}

.heady {
  padding: 0;
  color: $accentColor;
  font-weight: bold;

  @media screen and (max-width: 500px) {
    font-size: 1.25rem;
  }
  font-size: 1.5rem;
}

.headydropdown {
  @extend .heady;
  grid-column: 1 / -1;
}

.smallHeady {
  justify-self: center;
  color: $accentColor;
  font-weight: bold;
}

.submitButton {
  font-size: 1.25rem;
  background: #0652a0;
  color: white;
  border: white solid 2px;
  padding: 5px 10px;
  border-radius: 10px;
  margin: 10px 0;
  display: block;
  margin-left: auto;
  &:hover {
    border-color: #0652a0;
    color: #0652a0;
    background: white;
  }
}

.rightWrongWrapper {
  display: flex;
  align-items: start;
  gap: 10px;
  flex-basis: 60%;
  min-width: 60%;
}

.checkWrapper {
  min-width: 25px;
  width: 25px;
  display: flex;
  @media screen and (max-width: 400px) {
    min-width: 20px;
    width: 20px;
  }
  > img {
    width: 100%;
  }
}

.tellCorrectAnswerWrapper {
  display: flex;
  margin-top: 0.25rem;
  white-space: nowrap;
}

.tellCorrectAnswer {
  display: flex;
  align-items: center;

  padding: 0;

  color: #ef3340;

  > span {
    font-size: 1rem;
  }
  /* display: inherit; */

  > * {
    font-size: 1rem;
    font-weight: 600;
  }
}

.answerHighlite {
  white-space: normal;
  color: #ef3340;
  text-decoration: underline;
  @media screen and (min-width: 750px) {
    color: black;
    text-decoration: none;
  }
}

.answerHighliteFeedback {
  @extend .answerHighlite;
  color: #ef3340;
}

.formStyles {
  fieldset:nth-child(odd) {
    // background: rgba(211, 211, 211, 0.559);
  }
  > fieldset,
  > div {
    @media screen and (max-width: 750px) {
      padding: 0.5rem;
    }
    padding: 0.5rem 1rem;
  }
  > fieldset {
    display: flex;
    justify-content: space-between;
  }
}

.answerWrap {
  align-self: start;
  text-transform: capitalize;
  color: $accentColor;
  font-weight: 500;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;

  gap: 10px;
  > label {
    margin: 0;
  }
}

.correctAnswer {
  margin-right: 0.25rem !important;
  font-size: 1rem !important;
  display: none;
  @media screen and (min-width: 750px) {
    display: block;
  }
}

.nextWrapper {
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: 1rem;

  > button {
    font-size: 1.25rem;
    border-radius: 10px;
    padding: 0.25rem 1rem;
    background: #0652a0;
    color: white;
    border: none;
    box-shadow: 5px 5px 5px rgb(198, 198, 198);

    &:disabled {
      background: grey;
      color: white;
    }
  }
}

.done {
  color: green;
  display: flex;
  justify-content: center;
}
