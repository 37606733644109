@import '../../../stylesheets/_variables';
.flexBetween {
  display: flex;
  gap: 1rem;
}

.scenario {
  flex: 1;
  font-size: 1rem;
  color: black;

  @media screen and (min-width: 900px) {
    font-size: 1.25rem;
  }

  h2 {
    font-size: 1.5rem;
    color: $accentColor;
    @media screen and (min-width: 900px) {
      font-size: 1.75rem;
    }
  }
}

.scenarioDescription {
  font-weight: 400;
  padding: 0.5rem 0;
  border-bottom: #a7a7ad solid 2px;
  border-top: #a7a7ad solid 2px;
}

.dNdGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  background: black;
  padding: 2px;
  font-weight: 500;

  > .droppable {
    height: 100%;
    display: flex;
  }
}

.dragHolder {
  display: flex;
  flex-wrap: wrap;
  @media screen and (min-width: 330px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  background: inherit;
  font-weight: 500;
  gap: 10px;
  grid-auto-rows: min-content;
}

.statement {
  padding: 0.5rem 1rem;
  background: white;
}

.dndHeader {
  background: #bbbb;
  color: white;
  text-align: center;
  font-weight: 600;
  padding: 0.75rem 0.5rem;
}

.draggable {
  @extend .statement;
  background: $accentColor;
  color: white;
  flex: 1;
}

.droppable {
  padding: 0;
}

.correctAnswer {
  background: $correctColor;
}

.wrongAnswer {
  background: $incorrectColor;
}

.holderWrapper {
  margin-top: 1rem;
  border: black solid 2px;
  display: flex;
  flex-direction: column;
  background: rgb(137 137 137);
  > div {
    flex: 1;
    padding: 1rem;
  }
  > div,
  > div > section {
    height: 100%;
  }
}

.answerDroppable {
  background-color: white;
}

.teacherFlexWrapper {
  flex-basis: 25%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  display: none;
  @media screen and (min-width: 900px) {
    display: flex;
    font-size: 1rem;
  }
}

.minHeadWrapper {
  height: 70px;
  min-width: 70px;
  border: $accentColor solid 3px;
  border-radius: 100px;
  background-image: url('../../../assets/images/teacher-images/full-teacher.png');
  background-size: 85px;
  background-color: white;
  background-position: 19% -1%;
}

.smallFeedback {
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  margin-bottom: 1rem;
  grid-gap: 1rem;
  gap: 1rem;
  background: #8080805c;
  /* height: 5.625rem; */
  padding: 10px;
  color: black;
  font-weight: 500;
  min-height: 6.875rem;
  @media screen and (min-width: 500px) {
    font-size: 1rem;
  }
  @media screen and (min-width: 900px) {
    display: none;
  }
  > * {
    margin: 0;
  }
}
.waldoImgWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  background: $accentColor;
  border: black solid 4px;
}

.holder {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 2rem;
  flex-wrap: wrap;
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  background: $accentColor;

  > button {
    background-color: $secondaryColor;
    padding: 0.5rem 1.25rem;
    font-weight: 500;
    font-size: 1.5rem;
    color: black;
    border-radius: 10px;
    border: black solid 3px;
    display: flex;
    align-items: center;
    gap: 5px;
    box-shadow: 0px 0px 5px 0px black;

    &:hover {
      background: white;
      color: $secondaryColor;
    }
  }
}

.pulse {
  position: absolute;
  height: 50px;
  width: 50px;
  opacity: 0;
  border: 4px solid blue;
  border-radius: 40px;
  animation: pulsate 1s ease-out infinite;
  z-index: 10;
  transform: translate(-50%, -50%) !important;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.3, 1.3);
    opacity: 0;
  }
}

.whosWho {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: crosshair;
  > img {
    width: 100%;
  }
}

@keyframes notification-ping {
  0% {
    outline: 0rem solid rgba(255, 0, 0, 0);
    outline-offset: 0rem;
  }
  100% {
    outline: 0.5rem solid rgba(255, 0, 0, 0.715);
    outline-offset: 2rem;
  }
}

.selections {
  position: absolute;
  transform: translateY(-50%) translateX(6rem);

  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0.5rem;
  gap: 0.5rem;
  > button {
    padding: 0.25rem 0.75rem;
    font-size: 1.5rem;
    border: none;
    background: $secondaryColor;
    color: black;
    font-weight: 600;
    box-shadow: 0px 0px 5px 0px black;
    border: black solid 2px;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background: white;
      color: $secondaryColor;
    }
  }
}

.wrightSmall {
  background: white !important;
  border-color: $correctColor !important;
  color: $correctColor !important;
}

.wright {
  background: $correctColor !important;

  &:hover {
    background: $correctColor !important;
    color: black !important;
  }
  //make fade in in .2 s
}

//pulse animation keyframes

.tagger {
  position: absolute;
  z-index: 1;
  display: flex;
  width: 100px;
  height: 100px;
  pointer-events: none;
  transform: translate(-35%, -37%);
  @media screen and (min-width: 900px) {
    width: 150px;
    height: 150px;
  }
}

.smallImgWrap {
  padding: 0.25rem;
  border-radius: 1000px;
  overflow: hidden;
  background: white;
  border: black solid 2px;
}

.smallImg {
  height: 40px;
}

.storyTimes {
  font-size: 1.5rem;
}

.toast {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  opacity: 0;
  width: 100%;
  max-width: 320px;
  padding: 16px;
  gap: 16px;
  color: #6b7280; /* Equivalent to text-gray-500 */
  background-color: #ffffff; /* Equivalent to bg-white */
  border: 1px solid #e5e7eb; /* Equivalent to divide-gray-200 */
  border-radius: 8px; /* Equivalent to rounded-lg */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Equivalent to shadow */
  font-size: 1rem; /* Equivalent to text-sm */
  font-weight: 600; /* Equivalent to font-normal */
  top: 2rem;
  left: 2rem;
  z-index: 4000;
}

.icon {
  width: 50px;
}

.error {
  border: red solid 4px;
  color: red;
}

.success {
  border: green solid 4px;
  color: green;
}

.greyback {
  background: #373737dd;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.teacherModal {
  background: white;
  border-radius: 10px;
  max-width: 750px;
  padding: 2rem;
  padding-right: 3rem;
  border: $accentColor solid 8px;
  position: relative;
}

.content {
  display: flex;
  justify-items: center;
  align-items: center;

  > div {
    flex: 1;
  }

  img {
    //display none after 800px
    display: none;
    @media screen and (min-width: 1000px) {
      display: block;
      width: 100%;
    }
  }

  > p {
    font-size: 1.5rem;
    flex: 3;
  }
}
.rpg {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(228, 228, 228, 0.78);
  padding: 0.5rem;
  opacity: 0;
}

.innerWrap {
  display: flex;
  gap: 2rem;
  border: rgb(148, 147, 147) solid 2px;
  overflow: hidden;
  border-radius: 10px;
}

.imgWrap {
  position: relative;
  > img {
    width: 100%;
  }
}

.character {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  > img {
    width: 100%;
  }
}

.colText {
  padding: rem;
  display: flex;
  flex-direction: column;
  gap: 0;
  font-size: 1.25rem;
  flex: 4;
}

.text {
  font-weight: 500;
}

.disabled {
  pointer-events: none;
  color: #797979 !important;
  filter: grayscale(1);
}

.noCursor {
  //remove cursor from all children
  * {
    cursor: default !important;
  }
}

.cursor {
  cursor: pointer;
}

.toastMessage {
  margin-right: 0.25rem;
}
