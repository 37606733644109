.badgeGolder {
  background-color: white;
  height: 50px;
  width: 50px;
  border-radius: 1000px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    height: 50px;
    width: 50px;
    border-radius: 1000px;
  }

  //STOP SHOWING IT AT 500PX OR LOWER
  @media screen and (max-width: 650px) {
    display: none;
  }
}

.SBadge {
  top: 32px;
  right: 190px;
  transform: translate(-50%, -50%);
}

.TBadge {
  top: 32px;
  right: 130px;
  transform: translate(-50%, -50%);
}

.ABadge {
  top: 32px;
  right: 70px;
  transform: translate(-50%, -50%);
}

.CBadge {
  top: 32px;
  right: 10px;
  transform: translate(-50%, -50%);
}

.grey {
  filter: grayscale(1);
  opacity: 0.75;
}

.toggle {
  display: flex;
  align-self: center;
  border-radius: 100px;
  box-shadow: 0 0 5px grey;
  font-weight: bold;
  overflow: hidden;
  background: rgb(237, 237, 237);
  flex-direction: row;
  color: black;
  font-size: 1rem;
  align-self: flex-end;
  margin: 1rem;
}

.toggleSection {
  padding: 0.5rem 0.75rem;
  border: none;
  border-radius: 100px;
  background-color: inherit;
  font-weight: bold;
}

.active {
  background: #398abc !important;
  color: white;
}
