@import '../../../../stylesheets/variables';

.prompt {
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 2rem;
  @media screen and (min-width: 750px) {
    font-size: 1.5rem;
  }
}

.activityWrap {
  display: flex;
  justify-content: center;
}

.outerWrap {
  border: #0652a0 solid 4px;
  border-radius: 10px;
  padding: 1rem;
  background: #80808040;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
  max-width: 855px;
  width: 100%;
  @media screen and (min-width: 750px) {
    flex-direction: row;
  }
}

.assistantsCursor {
  cursor: url('https://i.ibb.co/wzkj7zG/icons8-paint-bucket-36.png'), auto;
  * {
    cursor: url('https://i.ibb.co/wzkj7zG/icons8-paint-bucket-36.png'), auto;
  }
}

.reinforcersCursor {
  cursor: url('https://i.ibb.co/4MwcFZf/icons8-paint-bucket-36.png'), auto;
  * {
    cursor: url('https://i.ibb.co/4MwcFZf/icons8-paint-bucket-36.png'), auto;
  }
}

.outsidersCursor {
  cursor: url('https://i.ibb.co/1XqMLxF/icons8-paint-bucket-36.png'), auto;
  * {
    cursor: url('https://i.ibb.co/1XqMLxF/icons8-paint-bucket-36.png'), auto;
  }
}

.defendersCursor {
  cursor: url('https://i.ibb.co/kHPf33n/icons8-paint-bucket-36.png'), auto;
  * {
    cursor: url('https://i.ibb.co/kHPf33n/icons8-paint-bucket-36.png'), auto;
  }
}

.peopleWrap {
  border-radius: inherit;
  background: white;
  display: grid;
  justify-content: center;
  margin: auto;
  gap: 0.5rem;
  padding: 1rem;
  flex: 1;
  grid-template-columns: repeat(4, 1fr);
  @media screen and (min-width: 400px) {
    grid-template-columns: repeat(5, 1fr);
  }
  width: 100%;
}

.legend {
  background: white;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  cursor: default;
  @media screen and (min-width: 750px) {
    align-self: start;
  }
}

.legendWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  font-size: 1rem;
  background-color: transparent;
  border: none;
  text-transform: capitalize;
  font-weight: 600;
  border: black solid 2px;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 3px 3px 5px gray;
  cursor: pointer;
  * {
    cursor: inherit;
  }
}

.active {
  @extend .legendWrap;
  color: white;
  background: black;
}

.circleSelect {
  width: 2rem;
  height: 2rem;
  border-radius: 100px;
}

.assistants {
  background: #a4bcd8;
  border: 2px solid #004993;
}

.reinforcers {
  background: #f9b4b9;
  border: 2px solid #ef3340;
}

.outsiders {
  background: #ffd8b5;
  border: 2px solid #ff9f40;
}

.defenders {
  background: #d8c6ff;
  border: 2px solid #9966ff;
}

.selectBystander {
  font-size: 1.25rem;
}

.personWrap {
  border: none;
  padding: 0;
  background: transparent;
  transition: 0.25s;
  fill: #afafaf;
  stroke: black;
}

.assistantsPerson {
  @extend .personWrap;
  fill: #a4bcd8;
  stroke: #004993;
}

.reinforcersPerson {
  @extend .personWrap;
  fill: #f9b4b9;
  stroke: #ef3340;
}

.outsidersPerson {
  @extend .personWrap;
  fill: #ffd8b5;
  stroke: #ff9f40;
}

.defendersPerson {
  @extend .personWrap;
  fill: #d8c6ff;
  stroke: #9966ff;
}

.submit {
  font-size: 1.25rem;
  padding: 0.25rem 0.5rem;
  background: $accentColor;
  color: white;
  border: white solid 2px;
  border-radius: 5px;
  box-shadow: 3px 3px 5px gray;
  transition: 0.25s;
  margin-top: 1rem;
  &:hover {
    color: $accentColor;
    background-color: white;
    border-color: $accentColor;
  }
}

.doubleWide {
  width: 100%;
  > div {
    border-radius: 10px;
  }
}

.whiteBackground {
  margin: 1rem 0;
  background: white;
  padding: 1rem;
}

.listHeader {
  font-size: 1.25rem;
  font-weight: 600;
}

.listy {
  font-size: 1rem;
  margin: 0;
  text-transform: capitalize;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  flex-direction: column;
  @media screen and (min-width: 600px) {
    flex-direction: row;
  }
}

.listGizzards {
  display: flex;
  flex-direction: column;
  border: #545454 solid 2px;
  box-shadow: 3px 3px 5px gray;
  background: #ecf0ed;
  white-space: nowrap;
  padding: 0.5rem;
}

.correct {
  color: $correctColor;
}

.incorrect {
  color: red;
}

.subHeader {
  color: #0653a0;
  /* padding: 0.25rem; */
  margin-bottom: 0;
  font-size: 1.5rem;
  margin: auto;
  text-align: center;
  margin: 0.5rem;
  margin-bottom: 1rem;
}

.oscrollG {
  width: 100%;
  overflow: scroll;
}
