.certificate {
  width: 100%;
  max-width: 800px;

  border: #a9aaad solid 2px;
  margin: auto;
  position: relative;
  display: block;
}

.activitySubmit {
}

.printWrapper {
  position: relative;
}
@media print {
  .printWrapper {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    @page {
      size: A4 landscape;
      margin: 0;
    }
  }
}

.activitySubmit {
  position: absolute;
  top: 48%;
  margin: auto;
  left: 31%;
  right: 29%;
  background: none;
  border: none;
  font-size: 2rem;
  text-align: center;
  padding: 1rem;
  cursor: pointer;
}

.printButton {
  font-size: 2rem;
  font-weight: 500;
  background-color: #3e85d3;
  font-family: 'Roboto', sans-serif;
  display: flex;
  color: white;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  box-shadow: none;
  border: black solid 2px;
  font-weight: 400;
  border-radius: 10px;
  margin: auto;
  margin-top: 1rem;
}
