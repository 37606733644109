@import '../../../stylesheets/variables';

.mySwiper {
  max-width: 800px;
  margin: 0 auto;
  height: 100%;

  .swiper-button-next,
  .swiper-button-prev {
    background: white;
    font-size: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: $primaryColor;
    &:after {
      font-size: 1.5rem;
      font-weight: bold;
    }

    @media screen and (max-width: 500px) {
      width: 30px;
      height: 30px;
      &:after {
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }

  .swiper-pagination-bullet {
    background: white;
    opacity: 1;
    height: 10px;
    width: 10px;
  }

  .swiper-pagination-bullet-active {
    background: $primaryColor;
  }
}

.slide-wrap {
  position: relative;
  margin: 0 auto;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  user-select: none; /* Standard */

  * {
    user-select: none !important; /* Standard */
  }

  &.end {
    padding: 2rem;
    background: $primaryColor;
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 2172 / 1727;
  }
  > img {
    -webkit-tap-highlight-color: transparent;
    width: 100%;
  }
  > .wwydImg {
    filter: blur(5px) brightness(0.7);
  }
}

.end-wrap {
}

.wwyd-wrap {
  position: absolute;
  z-index: 10;
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 500px) {
    justify-content: flex-start;
    margin-top: 1rem;
  }

  > h2 {
    font-size: 3rem;
    color: white;
    margin-bottom: 0;

    @media screen and (max-width: 500px) {
      font-size: 1.25rem;
    }
  }
}

.button-wwyd-wrap {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  gap: 10px;
  margin-top: 1rem;

  @media screen and (max-width: 500px) {
    margin-top: 0.5rem;
    gap: 5px;
  }

  > button {
    padding: 0.25rem 1rem;
    border-radius: 10px;
    background: #f59c52;
    border: 0;
    border: black solid 1px;
    box-shadow: 2px 2px black;
    color: black;

    @media screen and (max-width: 500px) {
      font-size: 1rem;
    }

    &.completed {
      background: white;
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
      > img {
        width: 30px;
      }
    }
  }
}

.try-another {
  bottom: 4rem;
  left: 4rem;
  padding: 0.25rem 1rem;
  border-radius: 10px;
  background: #f59c52;
  border: 0;
  border: black solid 1px;
  box-shadow: 2px 2px black;

  &:disabled {
    background: rgb(200, 200, 200);
    color: rgb(89, 89, 89);
    border: black solid 1px;
    box-shadow: 2px 2px black;
  }
}

.teacher-text {
  /* Scrollbar Styling Start */
  /* width */

  position: absolute;
  background: #ffffffeb;
  bottom: 0;
  font-size: 1.25rem;
  margin: 4.25rem;
  border: black solid 2px;
  border-radius: 10px;
  overflow: hidden;
  > .overflow-wrap {
    overflow-y: scroll;
    height: 100%;
    background: none;
    padding: 1rem;
  }

  @media screen and (max-width: 500px) {
    height: auto;
    top: 0;
    bottom: 0;
    margin: 2rem 2.75rem;
    font-size: 1rem;
  }
}
