@import 'variables';
.customButton {
  outline: none;
  color: $generalFontColor;
  border-radius: 10px;
  background-color: $primaryColor;
  border: 1px $primaryColor;
  box-shadow: 2px 2px black;
  border-radius: 10px;
  border: solid 2px black;
  padding: 0.25rem 1rem;
}
.CustomModalClose {
  outline: none;
  color: $background;
  border-radius: 10px;
  width: 150px;
  height: 2em;
  background-color: $primaryColor;
  border: 1px $primaryColor;
  box-shadow: 2px 2px black;
  border-radius: 10px;
  border: solid 2px black;
  position: absolute;
  bottom: 3vh;
}
.CustomModalClose:focus {
  outline: none;
}
.CustomModalClose:active {
  opacity: 0.5;
}
.customButton:focus {
  outline: none;
}
.customButton:active {
  opacity: 0.5;
}

button:focus {
  outline: none;
}

.options {
  outline: none;
  background-color: $secondaryColor;
  color: $generalFontColor;
  border-radius: 10px;
  border: black solid 2px;
  text-align: center;
  box-shadow: 2px 2px black;
  border-radius: 10px;
  /* border: solid 1px black; */

  &:disabled {
    background: grey;
    color: white;
  }

  &.completed {
    background: white;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > img {
      width: 30px;
      height: 30px;
    }
  }

  @media screen and (max-width: 750px) {
    font-size: 1rem;
  }
  font-size: 1.25rem;
}
.options:active {
  opacity: 0.5;
}
.options:focus {
  outline: none;
}

.optionsClose {
  position: absolute;
  right: 10px;
  right: 100;
  top: 1rem;
  margin-right: auto;
  right: 10;
  right: 10;
  width: 2.25rem;
  height: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  margin: 0;
  box-shadow: none;
  border-radius: 100px;
  background: transparent;
  border: none;

  &:hover {
    * {
      transition: 0.25s;
      stroke: red;
    }
  }
}

.intro5Button {
  width: 80%;
  font-size: 2vw;
  padding: 15px;
}
.leftArrow {
  outline: none;
  height: 60px;
  position: relative;
  left: 10px;
  top: 120px;
  cursor: pointer;
}
.rightArrow {
  outline: none;
  height: 25px;
  position: relative;
  left: 350px;
  top: 120px;
}
.arrowContainer {
  position: relative;
  left: -7vw;
  bottom: 30vh;
}

.inactive {
  opacity: 0.3;
  pointer-events: none;
}

.activitySubmit {
  outline: none;
  border-radius: 10px;
  font-size: 3vh;
  padding: 5px;
  background-color: $background;
  border: black;
  box-shadow: 5px 5px black;
  border-radius: 10px;
  border: solid 2px black;
  font-size: 1.25rem;
  margin-top: 1rem;
  background: $secondaryColor;
  color: white;
  /* display: block; */
  margin-left: auto;

  &:disabled {
    background-color: rgba(128, 128, 128, 0.52);
    color: grey;
    box-shadow: none;
  }
}
.activitySubmit:focus {
  outline: none;
}
.activitySubmit:active {
  opacity: 0.5;
}
.activityButtonContain {
  position: relative;
  height: 100%;
  bottom: 0px;
  // // bottom: -400px;
  // right: 12%;
}

.activityButton {
  outline: none;
  background-color: $secondaryColor;
  color: white;
  border-radius: 10px;
  padding: 25px 30px;
  border: 0px;
  margin: 15px;
  width: 40%;

  box-shadow: 2px 2px black;
  border-radius: 10px;
  border: solid 1px black;
  font-size: 5vh;
}

#ModalCloseContain {
  margin-top: 2rem;
}
.extend {
  width: 150%;
}
#textModalContain {
}

.trueFalse {
  padding: 1em;
  font-size: 1em;
  width: 8em;
}

#button {
  position: absolute;
  z-index: 20;
  height: 100px;
  width: 100px;
  background-color: red;
}
#test {
  background-color: pink;
  position: absolute;
  bottom: 10px;
  right: 200px;
  width: 4vw;
  z-index: 1;
}
.test {
  position: absolute;
  width: 10vw;
  font-size: 10pt;
  z-index: 1;
  right: 100px;
}

.toggleNext {
  background-color: $primaryColor;
  position: absolute;
  z-index: 10;
  right: 10px;
  color: white;
  border-radius: 5px;
  bottom: 10px;
  font-size: 0.6em;
}
.stacButtonBox {
  background-color: $primaryColor;
  width: 75vw;
  border-radius: 12px;
  position: absolute;
  left: 0px;
  bottom: 0px;
}
.stacButtons {
  font-family: $comicStrip;
}
.stacButtons:hover {
  opacity: 0.2;
}

.stacButtons:focus {
  outline: none;
}
