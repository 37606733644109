@import 'variables';

.footer {
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background: $primaryColor;
  width: 100vw;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  z-index: 25;
}

.Crumb {
  display: flex;
  height: 14px;
  border: $background solid 2px;
  aspect-ratio: 1;
  background: red;
  border-radius: 100px;
  background: $background;
  &.visited {
    background: $accentColor;
  }
}

.backButton {
}
.nextButton {
}
.nextButton:focus {
  outline: none;
}
.backButton:focus {
  outline: none;
}
.crumbBox {
  display: flex;
  justify-content: space-between;
  max-width: 60vw;
  background: $accentColor;
  border-radius: 50px;
  padding: 1rem 1.5rem;
  gap: 2px;
  flex: 1;
}

.footer-justBack {
  position: fixed;
  bottom: 0;
  border-bottom: solid $primaryColor 40px;
  border-top: solid $primaryColor 10px;
  display: flex;
  justify-content: space-between;
  background: $primaryColor;
  width: 100vw;
}
@media screen and (max-width: 1100px) {
  // .nextButton {
  //    margin-right: 1%;
  //    margin-left: -15%;

  // }
  .crumbBox {
    width: 55vw;
  }
}

@media screen and (max-width: 1100px) {
  .Crumb {
    height: 12px;
  }
}

@media screen and (max-width: 875px) {
  .Crumb {
    height: 10px;
  }
}
@media screen and (max-width: 750px) {
  .crumbBox {
    display: none;
  }
}
