@import '../../../stylesheets/variables';

.flex-down {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  max-width: 500px;
  width: 150px;
}

.bus {
  display: block;
  margin-top: rem;
  max-width: 200px;
  width: 200px;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-wrap {
  .error {
    font-size: 1rem !important;
    text-align: start;
  }
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid $primaryColor;

  .inner-wrap {
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
}

.auth-wrapper {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  box-sizing: border-box;
  background-color: $background;
  padding: 4rem 1rem;
  min-height: 100vh;
  background-image: url('/bus.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  // background: rgb(245, 156, 82);
  // background: linear-gradient(
  //   -45deg,
  //   rgba(245, 156, 82, 1) 6%,
  //   rgba(62, 133, 208, 1) 61%
  // );
  section {
    .inner-wrap {
      padding: 2rem;
    }

    .login-wrap {
      border-radius: 2px;
      max-width: 400px;
      margin: auto;
      border-radius: 10px;
      box-shadow: 3px 3px 7px 5px rgb(77, 77, 77);
    }
    h1 {
      margin: 0;
      font-size: 1.85rem;
      font-weight: 500;
      text-align: center;
      margin-bottom: 1rem;
    }
    form {
      padding: 1rem 0;
      display: flex;
      flex-direction: column;

      .input-wrap {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-size: 1rem;
        border: none;
        background-color: none;
        border-bottom: 2px solid lightgray;
        margin: 0.5rem 0;
        padding-left: 0.75rem;
        padding-bottom: 0.5rem;
        > input {
          width: 100%;
          border: none;
          background-color: none;
          &:focus {
            outline: none;
          }
        }
      }

      .login-svg {
        width: 15px;
        min-width: 15px;
        fill: gray;
      }
      label {
        display: flex;
        flex-direction: column;
        > p {
          margin: 0;
          font-size: 0.75rem;
          font-weight: 500;
          padding: 0;
          padding-left: 0.25rem;
        }
      }
      button {
        width: 100%;
        margin-top: 0.75rem;
        font-size: 1.25rem;
        padding: 0.5rem 0.75rem;
        border-radius: 100px;
        font-weight: 500;
        background: rgb(245, 156, 82);
        color: white;
        border: none;
        background: rgba(62, 133, 208, 1);
      }
    }
  }
}

.login-wrap.login {
  min-height: 100vh;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  .inner-wrap {
    border: 1px solid black;
    padding: 2rem;
  }
}

.sign-in-b-wrapper {
  @media screen and (max-width: 500px) {
    justify-content: space-between;
  }
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
  top: 1em;
  right: 1em;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    p {
      text-align: left;
      margin: 0;
      font-size: 1rem;
      font-size: 300;
      a {
        text-decoration: none;
        color: #5f2c86;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  a.sign-up,
  button {
    @media screen and (max-width: 500px) {
      padding: 0.25rem 0.5rem;
    }
    font-size: 1rem;
    padding: 0.75rem 1.25rem;
    color: white;
    background: #378abd;
    border: black solid 1px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 600;
  }
}

.grey {
  margin-bottom: 0 !important;
  color: #378abd;
}

.phoneLabel {
  display: flex;
  align-items: start;
  gap: 1rem;
  margin-top: 0.8em;
  font-size: 1rem;
  color: rgb(70, 70, 70);
  text-decoration: underline;
}
> span {
}

.makeBlueText {
  color: #378abd;
  text-decoration: underline;
  text-decoration-color: #378abd !important;
}

.reset-p {
  margin: auto;
  min-height: auto !important;
  background-color: white;
}

.forgot-username {
  display: flex;
  align-items: center;
}

.success-wrap {
  text-align: left;
  h1 {
    color: green;
  }
  p {
    font-size: 1.15rem;
  }
}

.format {
  margin-top: 0.5rem;
}

.forgot {
  font-size: 0.75rem;
  margin-left: auto;
  color: black;
}

.sign-up-thing {
  margin-top: 4rem !important;
  font-size: 1rem;
  margin: auto;
  > a {
    color: #378abd !important;
    text-decoration: underline !important;
  }
}

// .bg-example {
//   flex: 1;
//   background-image: url('/bus-color.jpg');
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: right;
// }
