@import 'general';
@import 'button';
@import 'nav';
@import 'images';
@import 'variables';
@import 'footer';
@import '_speechBubble';
@import '_bullying';
@import 'bystanders';
@import '_activityModal';
@import '_conclusion';
@import '_intro';
@import '_scenarios';
@import '_stac';
@import '_start';

#imageLoader {
  visibility: hidden;
}

.reactBody {
  background-color: $background;
  height: 100%;
  font-size: calc(17px + (35 - 17) * ((100vw - 300px) / (1600 - 300)));

  // font-family: $font1;
}

html.js-focus-visible {
  background-color: $background;
}

.modalContent {
  border: black solid 4px;
  height: auto;
  padding: 1rem 2rem;
  background-color: $background;
  width: 80vw;
  border-radius: 25px;
  color: $generalFontColor;
  font-size: 2em;
  max-width: 500px;
  overflow-y: scroll;
  max-height: 95vh;
  position: relative;
  ul {
    margin: 0;
    padding-top: 0;
  }
}

#titleParent {
  box-sizing: border-box;
  border-bottom: solid black 2px;
  font-family: $font1;
  margin-bottom: 2%;
  margin: 1rem 0;
  color: $generalFontColor;
  position: relative;
  > button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: lime;
    border: none;
    font-size: 1.5rem;
    padding: 0.5rem;
    color: $generalFontColor;
    &:hover {
      color: $accentColor;
    }
  }
  > h1 {
    margin-bottom: 0 !important;
    font-size: 1.5rem;
    @media screen and (min-width: 500px) {
      font-size: 2rem;
    }
    @media screen and (min-width: 780px) {
      font-size: 2.5rem;
    }
  }
  > h1.modalContentTitle {
    font-size: 1.5rem;
    padding-right: 1rem;
    @media screen and (min-width: 500px) {
      font-size: 2rem;
    }

    > h2.modalContentTitle {
      width: 100%;
      text-align: center;
    }
  }
  @media screen and (min-width: 780px) {
    margin-top: 0;
  }
}

.modalContentImages {
  background-color: $background;
  border-radius: 25px;
  color: black;
  font-size: 2em;
  width: 90vw;
  max-width: 1100px;
  max-height: 85vh;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 1rem 2rem 2rem 2rem;
  position: relative;
  @media screen and (min-width: 780px) {
    width: 80vw;
  }
  // right: 25%;
}

.modalText {
  font-size: 1.25rem;
  margin: 0;
}
li.modalText {
}

.startScreenContainer {
  text-align: center;
  padding: 1rem;
}

.title {
  padding: 1rem 0;
  font-family: $sansSerifPrimary;
  > h1 {
    font-size: 2rem;
  }
}

.titleText {
  margin-top: 0.5em;
}

.titleText1 {
  margin: 0 0.5rem;
  font-size: 80%;
}

ul {
  font-size: 70%;
  padding: 10px;
  color: $generalFontColor;

  li::marker {
    color: $secondaryColor;
  }
}

li {
  padding: 5px;
  // color: white;
}
.modalContent {
  ul {
    list-style: none;
  }
}

.scenario2 {
  background-color: #f9461c;
  border: 0;
  width: 775px;
  display: flex;
  padding: 15px;
  border-radius: 1em;
  justify-content: center;
  margin: 0 auto;
}

.scenario2text1 {
  position: relative;
  left: 10px;
  top: 15px;
  width: 100%;
  font-size: 12pt;
  font-weight: 600;
}

h1 {
  display: flex;
  justify-content: center;
}

.scenario2text2 {
  position: relative;
  left: 30px;
  width: 90%;
  font-size: 12pt;
  font-weight: 600;
}

.navigation {
  font-size: 1.15rem;
  font-weight: 500;
  background-color: $secondaryColor;
  font-family: $sansSerifPrimary;
  display: flex;
  align-items: center;
  gap: 5px;
  box-shadow: none;
  border: black solid 2px;

  &:disabled {
    background-color: gray;
    color: black;
    cursor: not-allowed;
  }
  svg {
    height: 15px;
  }
  @media (hover: hover) {
    &:hover {
      background-color: white;
      color: $secondaryColor;
      svg {
        * {
          fill: $secondaryColor;
        }
      }
    }
  }
}

.basketballInput {
  display: flex;
  background-color: pink;
}

textarea {
  outline: none;
  width: 100%;
}

// .textpanel img {
//   display: block;
// }Í
// .textpanel textarea {
//   position: absolute;
//   top: 50px;
//   left: 500px;
//   height: 200px;
//   width: 200px;
//   background-color: rgba(255, 255, 255, 0.5);
// }

.touchable {
  color: $generalFontColor;
  margin: 0 auto;
}

.flex {
  display: flex;
}

.font-2vh {
  font-size: 2vh;
}

#modalTextImages {
}

.introQuery {
  margin: 0 2rem;
  font-size: 1rem;
  @media screen and (min-width: 780px) {
    font-size: 1.25rem;
  }
}

.banner-p {
  font-size: 1.25rem;
  background: #ecf0ed;
  font-weight: 600;
  color: black;
  padding: 1rem 1.5rem;
  border-top: 2px solid gray;
  border-bottom: 2px solid gray;
  margin-bottom: 0;
}

.logo-wrapper {
}

.stac {
  margin: 0 5vw;
  // overflow: auto;
}

.steal {
  margin: 0 3vw;
  font-size: 1.2em;
}

@media screen and (max-width: 780px) {
  .introQuery {
    // font-size: 8pt;
  }

  .titleQuery {
    font-size: 15pt;
  }

  .stac {
    font-size: 8pt;
    overflow: auto;
  }
}

.modalContentTitle {
  font-size: 1.75rem;
  color: $accentColor;
}

.correct {
  color: $correctColor;
}

.incorrect {
  color: #f13340;
}

.reactBody {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mainSection {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.firework-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.fireworks {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-height: 1600px) and (max-width: 2560px) {
  .reactBody {
    // background-color: black;
  }
}

p {
  color: black;
}

.thinButton {
  margin: 0;
  line-height: normal;
  background-color: $primaryColor;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  border-radius: 10px;
  box-shadow: 2px 2px black;
  border: black solid 2px;
  font-weight: 600;
  text-align: left;
  @media screen and (min-width: 900px) {
    font-size: 1.25rem;
  }
}

.prompt {
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1rem;
  @media screen and (min-width: 750px) {
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
  }
}

.strategy {
  flex: 3;
  min-width: 50%;
}

.strat-wrap {
  display: flex;
  flex-wrap: wrap;
}

.img-strat {
  height: auto;
  flex: 0;
  width: 100%;
}

.img-wrap-strat {
  min-width: 300px;
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  h1,
  h2 {
    margin: 0.5rem auto !important;
  }
}

.audioButton {
  width: 50px;
  min-width: 50px;
  background: none;
  border: none;
  fill: $primaryColor;
  margin-left: auto;
  padding: 0;
  padding: 0.5rem 0;
}

.audioButton.hidden {
  visibility: hidden;
  margin-right: auto;
  margin-left: 0;
  @media screen and (max-width: 780px) {
    display: none;
  }
}

.modal-T {
  font-size: 1.75rem;
  border-bottom: 2px solid black;
  margin-bottom: 0.25rem;
  padding-bottom: 0.25rem;

  h1,
  h2,
  h3 {
    margin-right: 0.25rem;
    font-weight: 600;
    font-size: 1.75rem;
  }
}
