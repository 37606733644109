@import 'variables';

#bystandExplain {
  height: 60vh;
}

#assist {
  opacity: 0;
  position: absolute;
  left: 2000px;
  width: 80%;
}
#reinforcers {
  opacity: 0;
  position: absolute;
  left: 2000px;
  width: 80%;
}
#outsider {
  opacity: 0;
  position: absolute;
  left: 2000px;
  width: 80%;
}
#defend {
  opacity: 0;
  position: absolute;
  left: 2000px;
  width: 80%;
}
.photoContainer {
  position: relative;
  width: 33vw;
  background: transparent;
  // border:solid black 2px;
}

#hoverImage {
  height: 32vh;

  // background: lightgrey
}
.resolutionFix {
  width: 100%;
  float: left;
  // width: 40%;
  margin: 0 30px 20px 0;
  object-fit: contain;
  background-color: transparent;
  border-radius: 10px;
}
#physicalHover {
  opacity: 0;
  width: 32vw;
  height: 32vh;
}
#verbalHover {
  opacity: 0;
  width: 32vw;
  height: 32vh;
}
#relationHover {
  opacity: 0;
  width: 32vw;
  height: 32vh;
}
#cyberHover {
  opacity: 0;
  width: 32vw;
  height: 32vh;
}
#assistantsHover {
  position: absolute;
  opacity: 0;
  width: 32vw;
  height: 32vh;
}
#reinforcersHover {
  position: absolute;
  opacity: 0;
  width: 32vw;
  height: 32vh;
}
#outsidersHover {
  position: absolute;
  opacity: 0;
  width: 32vw;
  height: 32vh;
}
#defendersHover {
  position: absolute;
  opacity: 0;
  width: 32vw;
  height: 32vh;
}

.bystandDescription {
  color: black;
  top: 0vh;
  height: 45%;
  font-size: 0.8em;
}

.chalkboard {
  color: white;
  font-family: $chalkFont;
  // opacity: .5;
  font-size: 2.7vh;
  font-weight: 400;
  line-height: 30px;
  // padding-top: 40px;
}

.bystand {
  width: 100%;
  height: 11vh;
  font-weight: 500;
  font-size: 70%;
  overflow: hidden;
  box-sizing: border-box;
}

.consequenceButton {
  width: 80%;
  height: 90%;
  width: 90%;
  // font-size: 60%;
  font-weight: 600;
  box-sizing: border-box;
}
.bystandContain {
  display: flex;
  justify-content: space-between;
}

.incorrectText {
  font-size: 2vh;
}

.activityModalClose {
  position: absolute;
  top: -10px;
  left: 0px;
  height: 4vh;
  width: 4vh;
  font-size: 2vh;
  padding: 0;
  color: $secondaryColor;
  background-color: $background;
}
#Activity1 {
  z-index: 10;
}
#Activity2 {
  position: absolute;
  opacity: 0;
  top: 0px;
  left: -10px;
  z-index: 0;
}

.timer {
  position: absolute;
  z-index: 100;
  font-size: 100pt;
  top: 0;
  color: black;
}

.timer {
  opacity: 0;
  position: absolute;
  top: 42vh;
  right: 3.5vw;
  z-index: 1;
  width: 50%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
