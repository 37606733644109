.stacContainer {
  justify-items: center;
  align-items: center;
  display: grid;
  margin: auto;
  padding: 2rem;
  grid-template-columns: repeat(4, 1fr);
  min-width: 400px;
  flex: 1;
}

.stacImg {
  flex: 1;
  max-height: 100%;
  max-width: 100%;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}
