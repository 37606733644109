.reviewModalContent {
  text-align: center;
  font-size: 20pt;
  font-family: $font1;
  margin: 0 10%;
}

.petitionParent {
  position: absolute;
  top: 0px;
  right: 0%;
  height: 5%;
  // border: solid 2px black;
  width: 60%;

  height: 100%;
}
.petition {
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0px;
  top: 0px;
}
.petitionInput {
  position: absolute;
  right: 20%;
  width: 50%;
  top: 64%;
  font-size: 3vh;
  height: 40px;
  font-family: $handWriting;
  background: transparent;
  border: none;
}

.petitionInput:focus {
  outline: none;
}
.speechBubbleParent {
  position: absolute;
  width: 100%;
  height: 80%;
  left: -10%;
  bottom: 20%;
  opacity: 1;
  overflow: hidden;
}
.speechBubbleParentIntro {
  position: absolute;
  width: 100%;
  height: 80%;
  left: -8%;
  bottom: 20%;
  opacity: 1;
  overflow: hidden;
}

.titleConclusion {
  opacity: 0;
  font-size: 40pt;
}

.signedName {
  position: absolute;
  right: 20%;
  width: 50%;
  top: 55%;
  font-size: 3vh;
  z-index: 15;
  height: 40px;
  font-family: $handWriting;
  background: transparent;
  //    border: solid 2px black;
  color: black;
  opacity: 0;
}
.finish {
  // display: none;
  visibility: hidden;
}

.certificate {
  position: absolute;
  right: 2vw;
  width: 55%;
}

.name {
  color: black;
  background-color: transparent;
  position: absolute;
  z-index: 20;
  right: 0vw;
  top: 40vh;
  width: 55%;
  text-align: center;
}

#Conclusion {
  .speechBubbleParent {
    position: absolute;
    // right: 1000px;
    // left: -20px;
  }
  .titleConclusion {
    font-size: 100%;
  }
}
