@import 'variables';

.bullyFacts {
  font-size: 2vh;
  position: absolute;
  bottom: 1.5em;
  right: 2em;
  box-shadow: 5px 5px black;
  border-radius: 10px;
  border: solid 2px black;
}

.BullyFacts2Box {
  font-size: 3vh;
  position: absolute;
  bottom: 1.5em;
  right: 2em;
  box-shadow: 5px 5px black;
  border-radius: 10px;
  border: solid 2px black;
  width: 30%;

  height: 50%;
}

#bullyfactsBubble {
  position: absolute;
  left: 100px;
}

#crumbledPhantom {
  opacity: 0;
}

.infoButtonContainer {
  border-radius: 10px;
  flex-wrap: wrap;
  background: $primaryColor;
  display: flex;
  width: 50%;
  height: 40vh;
  padding: 1vh;
  font-size: 2vh;
  justify-content: space-between;
  right: 10%;
}

#paperContainer,
.paperContainer {
}

.fireText {
  position: absolute;
  // padding-left:30
  border: none;
  font-size: 2.5vh;
  padding: 10%;
  top: 0;
  background: transparent;
  width: 31vh;
  height: 30vh;
  top: 2vh;
  left: 1vw;
  // cursor: pointer;
  z-index: 2;
  font-family: $handWriting;
}

.paper {
  height: 35vh;
  left: 1vh;
  bottom: 2em;
  transform: rotate(10deg);
  z-index: 1;
}

#burningNoteDirections {
}

#puffOfSmoke {
  position: absolute;
  width: 40%;
  right: 10px;
  opacity: 0;
  z-index: 0;
  bottom: 10px;
  background: transparent;
}

.fire {
  z-index: 3;
  bottom: 0;
  right: 0em;
  height: 45vh;
  width: 40vw;
}

#crumbled {
  position: absolute;
  bottom: 0px;
}

#fire {
  // position: absolute;
  z-index: 0;
}

#bullyTypeSelector {
  background: $primaryColor;

  top: 97px;
  right: 5vw;
  // height: 58vh;
  width: 25vw;
  border-radius: 15px;
  box-shadow: 5px 5px black;
}

#crumbled {
  position: absolute;
  bottom: -50vh;
}

.avatarSizeFix {
  height: 15vh;
  margin: 8px;
}

.phantom {
  opacity: 0;
  position: absolute;
  bottom: 0;
}

.teacherContainer {
  align-self: end;
  margin-right: 2rem;
  .teacherImage {
    height: 300px;
  }
}

.fade-in-index {
  opacity: 0;
  animation: fadeIN 0.5s 0.5s forwards;
}

@keyframes fadeIN {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rankedDnd {
  width: 100%;
  max-width: 768px;
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;
  padding: 15px;
}

.cardContianer {
}

.customCard {
  // display: flex;
  // justify-content: center;
  margin: 1em;
  color: $background;
  background-color: $primaryColor;
  border-radius: 10pt;
  // padding: 1.1em;
  padding: 0.5em 0em 2em 0em;
  text-align: center;

  font-size: 60%;
  text-align: center;
}
.customCard.options.bystand {
  font-size: 60%;
  font-weight: 800;
}

#BubbleRanked {
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  bottom: 4vh;
  left: -4vw;
}

.containMan {
  z-index: 100;
  width: 40vw;
  height: 100%;
  background-color: pink;
  border: solid 2px black;
}

.chart {
  position: absolute;
  // display: none;
  // opacity: 0;
  z-index: 0;
  right: 20px;
  border: solid 2px black;
  position: absolute;
  width: 35vw;
  height: 25vw;
  background: 'rgba(0, 27, 45, 0.9)';
}

rect {
  color: blue;
}

// path.action-voronoi{
//     width:10px;
// }

.RankDnd {
  opacity: 0.1;
}

.testButton {
  // position: absolute ;
  // left: 100px;
  background-color: pink;
  border: solid 2px black;
}

.customSpeech1 {
  width: 23vw;
}

#rankedAnswerBubble {
  visibility: hidden;
  position: absolute;
  left: 13vw;
  bottom: 13vh;
  z-index: 100;
  height: 30vh;
}

@media screen and (max-width: 1450px) {
  #rankedAnswerBubble {
    left: 15vw;
    bottom: 15vh;
    z-index: 100;
  }
}

.answerWrap {
  opacity: 0;
  align-items: center;
  gap: 1rem;
  display: none;
  > img {
    width: 75%;
  }
}

.order-lis {
  padding: 1rem;
  background: white;
  margin: 1rem 0;
  padding: 0.5rem 0.5rem 0.5rem 2rem;

  .bold {
    font-weight: bold;
  }
}

.overflow-container {
  overflow-x: scroll;
  width: 100%;
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: gray;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background-color: gray;
  }
}

.chartContainer {
  background: white;
  padding: 1rem;
  min-width: 475px;
  width: 100%;
}

.rankedChart {
  width: 100%;
  object-fit: contain;
}

.AnswerPanel {
  position: inherit;

  width: inherit;
  text-align: center;
  overflow: hidden;
  width: 75%;
  flex: 1;
  // height: 50%;
  // max-height: 50%;
}

.panelContainer {
  display: flex;
  justify-content: center;
}

.bubble {
  position: relative;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 24px;
  width: 300px;
  background: #fff;
  border-radius: 40px;
  padding: 24px;
  text-align: center;
  color: #000;
  border: black solid 2px;
  position: relative;
}

.bubble:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  top: 100px;
  border: 25px solid;
  border-color: black transparent transparent black;
}

.bubble:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  top: 100px;
  border: 15px solid;
  border-color: #fff transparent transparent #fff;
}

.orderBox {
  background: #ecf0ed;
  border-radius: 10px;
  padding: 1rem;
  width: 100%;
  max-width: 700px;
  @media screen and (min-width: 750px) {
    padding: 2rem;
  }

  p {
    color: black;
    font-size: 1.25rem;
    @media screen and (min-width: 750px) {
      font-size: 1.5rem;
    }
  }
}

#orderContainer {
  /* background: black; */
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px;
  border-radius: 10px;
  /* padding: 1rem 0; */
  gap: 1rem;
  padding: 1rem;
  background: white;
  > .movableCard {
    padding: 1rem;
    color: black;
    background: white;
    /* text-align: center; */
    font-weight: 400;
    color: white;
    background: $accentColor;
    font-size: 1rem;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    font-weight: 500;
    &.correctCard {
      background-color: $correctColor;
    }
    @media screen and (min-width: 750px) {
      font-size: 1.25rem;
    }
    > .dragIconWrap {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      > button {
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: none;

        width: 2rem;
        height: 2rem;
        &:hover {
          > img {
            transform: scale(0.9);
          }
        }
      }
      img {
        width: 100%;
      }
    }
  }
}

.movableCard {
  font-size: 0.75rem;
  @media screen and (min-width: 750px) {
    font-size: 1rem;
  }
}

.answerTextWrap {
  display: none;
  opacity: 0;
  > p {
    color: black;
  }
}

.list-item-ranking {
  font-size: 1rem;
  font-weight: 500;
  color: black;
}
