@import '../../../stylesheets/variables';

.booster-head-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.backpack-wrap {
  width: 75px;
  height: 75px;
  background: white;
  border-radius: 100px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border: black solid 2px;

  > img {
    height: 100%;
    width: auto;
  }
}

.boost-logo {
  width: 50px;
  > img {
    width: 100%;
  }
}

.flex-boost {
  display: flex;
  align-items: start;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 1rem;
    align-items: start;
  }
}

.today {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.make-bigger {
  flex: 1;
  margin-right: 2rem;
}

.teo {
  flex: 3;
}

.semibold {
  font-weight: 700;
}

.playButton-vid {
  /* top: 50%; */
  width: 25%;
  height: auto;
  /* height: 25%; */
  position: absolute;
  background: transparent;
  border-radius: 1000px;
  border: white solid 4px;
  position: absolute;
  /* left: 0; */
  /* right: 0; */
  margin: auto;
  /* top: 0; */
  /* bottom: 0; */
  left: 50%;
  transform: translate(-50%, 0);
  /* margin-left: -12.5%; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: #3e85d3; */
  padding: 0;
  transform-origin: center;
  animation: pulse 2s infinite;
  z-index: 100;
  > svg {
    fill: $primaryColor;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
  }
}

.vid-wrap {
  position: relative;
  flex: 4;
  min-width: 50%;
  > video {
    height: auto;
    width: 100%;
    border-radius: 10px;
  }

  > img {
    width: 100%;
  }
}

.backpack-poped {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  .packl {
    animation: shake 0.5s 3 linear forwards;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    width: 250px;
    text-align: center;

    @media (max-width: 800px) {
      width: 150px;
      font-size: 1rem;
    }
    > img {
      width: 100%;
    }
  }
  img {
    max-width: 300px;
    height: auto;
  }
}

//animation that shakes the backpack
@keyframes shake {
  0% {
    scale: 1;
  }
  50% {
    transform: rotate(10deg);
    scale: 1.2;
  }
  100% {
    transform: rotate(0deg);
    scale: 1;
  }
}

.puppy-time {
  margin-top: 1rem;
}

.banner {
  font-weight: 600;
  color: black;
  padding: 1rem 1.5rem;
  display: flex;
  gap: 1rem;
  margin: 1rem 0;
  font-size: 1rem;
  font-size: 1rem;
  flex-wrap: wrap;
  @media screen and (min-width: 900px) {
    font-size: 1.25rem;
    flex-wrap: nowrap;
  }
  display: flex;
}

.wrappp {
  min-width: 200px;

  * {
    width: 100%;
  }
}

.quiz {
  overflow: hidden;

  background: white;
  border-radius: 10px;
  border: black solid 3px;
  .questionxs {
    display: flex;
    font-size: 1.25rem;
    gap: 1rem;
    padding: 1.25rem 2rem;
    flex-wrap: wrap;
    @media screen and (min-width: 900px) {
      font-size: 1.25rem;
      flex-wrap: nowrap;
    }
  }
  p {
    font-size: 1.5rem;
    margin: 0;
  }
}

.tracker {
  // background: lightgray;
  padding: 1rem;
  // border-radius: 10px;
  > h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
  }
}

.trackerAnswer {
  display: flex;
  align-items: start;
  flex-wrap: nowrap;
  gap: 10px;
  margin: 0;
  color: #3270a6;
  font-weight: bold;
  > * {
    margin: 0;
    padding: 0;
  }

  input[type='radio'],
  input[type='checkbox'] {
    margin-top: 0.25rem;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    min-height: 1.5rem;
    accent-color: $primaryColor;
  }
}

.tracker-button-wrap {
  display: flex;
  justify-content: space-between;

  &.single {
    justify-content: end;
  }

  padding: 1rem 1.5rem;
  background: lightgrey;
  button {
    background-color: $primaryColor;
    color: white;
    > svg {
      * {
        fill: white;
      }
    }
    &:hover {
      color: $primaryColor;
      > svg {
        * {
          fill: $primaryColor;
        }
      }
    }
  }
}

.messages {
  padding: 1rem 1.5rem;
  background: rgb(245, 156, 82);
  background: linear-gradient(
    -45deg,
    rgba(245, 156, 82, 1) 6%,
    rgba(62, 133, 208, 1) 61%
  );
  > p {
    padding: 1rem 1.5rem;
    border-radius: 10px;
    background: white;
  }
}

.options-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.flax {
  flex: 2 !important;
  max-width: none !important;
  .completed {
    background: white;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: space-between;
  }
}

.booster-modal {
  border: black solid 4px;
  padding: 1rem 2rem;
  background-color: #ecf0ed;
  border-radius: 25px;
  color: black;
  font-size: 1rem;
  overflow-y: scroll;
  position: relative;
  width: 90%;
  max-width: 900px;
  max-height: 90vh;
  overflow: scroll;

  @media screen and (min-width: 900px) {
    font-size: 1.5rem;
  }
}

.mytitle {
  text-align: center;
}

.pop-up-wrap {
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  padding: 0;
  padding-bottom: 2rem;
  flex-wrap: wrap;

  @media screen and (min-width: 800px) {
    padding: 0.5rem 2.25rem;
    padding-bottom: 2rem;
    align-items: center;
  }
}

.booster-text {
  max-width: 500px;
  min-width: 50%;
  flex: 1;
  font-size: 1rem;
  @media screen and (min-width: 800px) {
    font-size: 1.5rem;
  }
}

.popUp-swipe {
  max-width: 850px;

  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
}

.booster-img-wrap {
  max-width: 250px;
  min-width: 200px;
  > img {
    width: 100%;
  }
}

.tons {
  padding: 5px 10px;
  background: $primaryColor;
  color: white;
  border-radius: 10px;
  box-shadow: 2px 2px #000;
  border: 2px solid #000;
}

.check {
  width: 30px;
}

.popUp-swipe {
  .swiper-wrapper {
    display: flex;
    align-items: end;
    padding-bottom: 3rem;
  }

  .next,
  .prev {
    font-size: 1rem;
    bottom: 0;
    top: auto !important;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 5px;
    box-shadow: none;
    border: black solid 2px;
    width: fit-content;
    background-color: $secondaryColor;
    color: black;
    outline: none;
    border-radius: 10px;
    padding: 0.25rem 1rem;
    height: auto;
    text-transform: uppercase;
    font-size: 1.15rem;
    gap: 5px;
    > svg {
      height: 15px;
      * {
        fill: black;
      }
    }
    &:hover {
      background-color: white;
      color: #f59c52;
      > svg {
        * {
          fill: #f59c52;
        }
      }
    }
  }

  .next {
    margin-left: auto;
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after,
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    display: none;
  }
  .swiper-button-disabled {
    display: none;
  }
}

.swiper-no-swiping {
  .swiper-button-next,
  .swiper-button-prev {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
}

.wrapppppp {
  display: flex;
  flex-direction: column;
  flex: 2;
  min-width: 300px;

  > h3 {
    text-align: center;
    margin-bottom: 1rem;
  }
}

.centerHeader {
  width: 100%;
  text-align: center;
}
