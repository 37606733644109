.desc {
  color: rgba(255, 255, 255, 0.4);
  margin: 0 0 2rem 0;
  display: block;
}

.reset-button {
  background: #474de924;
  color: rgba(255, 255, 255, 0.5);
  padding: 0.25rem 0.5rem;
  border-radius: 100px;
  display: inline-block;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 400;
  margin: 0 0 0 1rem;
}

.reset-button:hover {
  color: white;
}

.item-holder {
  display: flex;
  height: 360px;
  flex-wrap: nowrap;
  overflow: auto;
  width: 100%;
}
.item {
  width: 300px;
  min-width: 300px;
  @media screen and (min-width: 650px) {
    width: 500px;
    min-width: 500px;
  }
  background: white;
  overflow: visible;
  position: relative;
  aspect-ratio: 327/400;
}

.shredWrap {
  height: 100%;
  width: 100%;
  padding: 0;
}

.animation-assets > div {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.delete:hover {
  background: linear-gradient(45deg, rgb(10 13 37), rgb(17 21 52));
  transform: scale(1.05);
  color: white;
}

.item.zoom {
  animation: zoom forwards 0.7s ease-out 1;
}

.item.shredding {
  //   animation: reduceWidth forwards 1.7s ease-out 1;
}

.item.fall {
  animation: fallAway forwards 1s ease-out 1;
}
.item.analogue {
  animation: analogue forwards 1s ease-out 1;
}

@keyframes fallAway {
  0% {
    transform: rotateZ(0deg);
    top: 0;
    opacity: 1;
  }
  25% {
    transform: rotateZ(-15deg);
  }
  100% {
    top: 300px;
    transform: rotateZ(-5deg);
    opacity: 0;
  }
}

@keyframes analogue {
  0% {
    clip-path: inset(0px 0px 0px 0px);
  }
  50% {
    clip-path: inset(125px 0 125px 0);
  }
  80% {
    clip-path: inset(500px 0 500px 0);
  }
  85% {
    width: 500px;
    min-width: 500px;
    padding: 1rem;
    margin: 0 2rem 0 0;
    clip-path: inset(500px 0 500px 0);
  }
  100% {
    min-width: 0;
    width: 0;
    padding: 0;
    margin: 0;
    clip-path: inset(500px 0 500px 0);
  }
}
@keyframes reduceWidth {
  0% {
    width: 500px;
    padding: 1rem;
  }
  80% {
    width: 500px;
    min-width: 500px;
    padding: 1rem;
  }
  100% {
    width: 500px;
    min-width: 500px;
    padding: 1rem;
  }
}
@keyframes zoom {
  0% {
    transform: scale(1);
    width: 500px;
    min-width: 500px;
    opacity: 1;
    transform-origin: 125px 125px;
  }
  50% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    width: 0;
    min-width: 0;
    transform: scale(0);
    opacity: 0;
    padding: 0;
    margin: 0;
  }
}

@keyframes spinALittle {
  0% {
    top: 0;
    transform: rotateY(0deg) rotateX(0deg);
  }
  10% {
    transform: rotateY(-4deg) rotateX(-30deg);
  }

  25% {
    top: 260px;
  }
  50% {
    top: 490px;
    transform: rotateY(2deg) rotateX(30deg);
  }
  75% {
    top: 760px;
    width: 500px;
    min-width: 500px;
    transform: rotateY(0deg) rotateX(0deg);
  }
  100% {
    top: 800px;
    width: 0;
    min-width: 0;
  }
}

@keyframes spinALittleSmall {
  0% {
    top: 0;
    transform: rotateY(0deg) rotateX(0deg);
  }
  10% {
    transform: rotateY(-4deg) rotateX(-30deg);
  }

  25% {
    top: 260px;
  }
  50% {
    top: 490px;
    transform: rotateY(2deg) rotateX(30deg);
  }
  75% {
    top: 760px;
    width: 300px;
    min-width: 300px;
    transform: rotateY(0deg) rotateX(0deg);
  }
  100% {
    top: 800px;
    width: 0;
    min-width: 0;
  }
}

@keyframes spinALittleAltSmall {
  0% {
    top: 0;
    transform: rotateY(0deg) rotateX(0deg);
  }
  25% {
    transform: rotateY(3deg) rotateX(22deg);
    top: 240px;
  }
  50% {
    top: 510px;
    transform: rotateY(-4deg) rotateX(-30deg);
  }
  75% {
    top: 740px;
    width: 300px;
    min-width: 300px;

    transform: rotateY(2deg) rotateX(19deg);
  }
  100% {
    top: 800px;
    width: 0;
    min-width: 0;
  }
}

@keyframes spinALittleAlt {
  0% {
    top: 0;
    transform: rotateY(0deg) rotateX(0deg);
  }
  25% {
    transform: rotateY(3deg) rotateX(22deg);
    top: 240px;
  }
  50% {
    top: 510px;
    transform: rotateY(-4deg) rotateX(-30deg);
  }
  75% {
    top: 740px;
    width: 500px;
    min-width: 500px;

    transform: rotateY(2deg) rotateX(19deg);
  }
  100% {
    top: 800px;
    width: 0;
    min-width: 0;
  }
}

@keyframes dropShadow {
  0% {
    filter: none;
  }
  100% {
    filter: drop-shadow(0px 2px 30px rgba(0, 0, 0, 0.3));
  }
}
.shredding {
  background: transparent;
  box-shadow: none;
}
.shredding > .image,
.shredding .delete,
.shredding .text {
  opacity: 0;
}
.animation-assets {
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
  perspective: 1000px;
  z-index: 15;
}
.animation-assets > .item-wrap > .item {
  animation: 2s linear forwards;
  perspective: 1000px;
  position: absolute;
  top: 0;
  left: 0;

  &.spinALittleAlt {
    animation-name: spinALittleAltSmall;
    @media screen and (min-width: 650px) {
      animation-name: spinALittleAlt;
    }
  }
  &.spinALittle {
    animation-name: spinALittleSmall;
    @media screen and (min-width: 650px) {
      animation-name: spinALittle;
    }
  }
}

.item-wrap {
  animation: dropShadow 0.1s ease-out forwards 1;
}

.absolute-me {
  background-color: white;
  position: absolute;
  top: 0px;
  border: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
