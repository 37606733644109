.nextWrapper {
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: 1rem;
  margin-left: auto;

  > button {
    font-size: 1.25rem;
    border-radius: 10px;
    padding: 0.25rem 1rem;
    background: #0652a0;
    color: white;
    border: none;
    box-shadow: 5px 5px 5px rgb(198, 198, 198);

    &:disabled {
      background: grey;
      color: white;
    }
  }
}
