@import 'variables';
.justify-left {
  position: absolute;
  left: 0px;
}
.justify-top {
  position: absolute;
  top: 0px;
}
.justify-right {
  position: absolute;
  right: 0px;
}
.justify-bottom {
  position: absolute;
  bottom: 0px;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-around {
  display: flex;
  justify-content: space-around;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.flex-column-even {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.basicTopDirections {
  background-color: $primaryColor;
  font-size: 1.5rem;
  box-shadow: 5px 5px black;
  padding: 20px;
  border-radius: 10px;
}

.practice-time {
  background: $primaryColor;
  width: 100%;
  border-radius: 15px;
  box-shadow: 5px 5px black;
}

// .heightScale{
//   font-size: 1vh
// }

.hide {
  display: none;
}

.questionaireBox {
  width: 100%;
  box-shadow: 6px 6px 7px 0px rgb(193 196 194);
  border: black solid 2px;
  background: $primaryColor;
  border-radius: 10px;
  padding: 0.5em;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  * {
    margin: 0;
  }
}

.learningModule {
  position: relative;
  flex-direction: column;
  background-color: transparent;
  left: 15%;
}

.explanation {
  text-align: left;
  font-size: 1.25rem;
  color: black;
  flex: 1;
  flex-basis: 500px;
  align-self: start;
  font-weight: 500;
  /* min-width: 500px; */
  margin-bottom: 0;
}

.negSpeechWrap {
  text-align: left;
  font-size: 1.25rem;
  color: black;
  /* flex: 1 1; */
  /* flex-basis: 500px; */
  align-self: start;
  font-weight: 500;
  /* min-width: 500px; */
  margin-bottom: 0;
  margin-right: 0;
  @media screen and (min-width: 750px) {
    margin-right: 2rem;
  }
}

.introDef {
  font-size: 1.25rem;
  font-weight: 500;
  @media screen and (min-width: 750px) {
    text-align: center;
    font-size: 1.75rem;
  }
}

// general styling for activity area
.wrapper {
  background-color: $background;
  width: 100vw;
  height: 50vh;
  border: 2px black;
  .container {
    width: 70vw;
  }
}

.activityShadow {
  width: 90%;
  padding: 2rem 0;
  margin: 0 auto;

  border-radius: 20px;
}

.activityContainer {
  max-width: 1250px;
  margin: auto;
  border-radius: 10px;
  position: relative;
  background-color: white;
  width: 100%;
  border: solid 2px black;
  box-sizing: border-box;
  box-shadow: 6px 6px 7px 0px rgb(128 133 130);
  overflow-anchor: none;
  padding: 10px;
  @media screen and (min-width: 500px) {
    padding: 20px;
  }
}

.contain {
  margin-top: 2%;
  background-color: $primaryColor;
  height: 90%;
  flex-direction: row;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 8px 8px 6px -5px black;
  -moz-box-shadow: 8px 8px 6px -5px black;
  box-shadow: 8px 8px 6px -5px black;
  border-radius: 10px;
}

@media screen and (max-width: 1450px) {
  .learningModule {
    font-size: 1vw;
  }
}
