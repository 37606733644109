@import 'variables';

.color-nav {
  background-color: $primaryColor;
  -webkit-box-shadow: 0px 8px 6px -5px black;
  -moz-box-shadow: 0px 8px 6px -5px black;
  box-shadow: 0px 8px 6px -5px black;
}
.navBarLogo {
  position: relative;

  padding-left: 5px;
  height: 50%;
  width: 50%;
}

.mr-auto {
  display: flex;
  justify-content: initial;
  margin: 0;
  padding: 0;
  border: 0;
}
.personIcon {
  display: flex;
  justify-content: space-between;
  width: 15%;
}
.breadCrumbItemActive {
  font-size: 12pt;
  font-family: $sansSerifPrimary;
  margin: 0.25rem;
  color: white;
}
.active {
  font-weight: 900;
}
.review {
  width: 300px;
}

li.nav-item {
  float: left;
  display: flex;
  justify-content: initial;
  padding: 0;
  gap: 1rem;
}

.navUprights {
  color: $titleColor;
}
.inactiveNav {
  color: $titleColor;
  background-color: $primaryColor;
  font-weight: 500;
  @media screen and (max-width: 1150px) {
    display: none !important;
  }
}

.icon-wrapper {
  position: absolute;
}

nav.color-nav.navbar {
  padding: 0.5rem;
  z-index: 10;
}
.blankBar {
  height: 1.5em;
}

.navbar-nav {
  margin: auto;
}
