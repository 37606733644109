.stac {
  font-size: 2.2vh;
}

#comic1 {
  opacity: 1;
}
#comic2 {
  opacity: 0;
}
// ComicNavForward
.comicNav {
  position: absolute;
  bottom: 5vh;
  left: 30vw;
}
// ComicNavBack

.comicNavBack {
  position: absolute;
  bottom: 5vh;
  left: 6vw;
}

@media screen and (max-width: 1400px) {
  .comicNav {
    width: 10vw;
    left: 30vw;
    margin-top: 2px;
  }
  // ComicNavBack

  .comicNavBack {
    width: 10vw;
    bottom: 5vh;
    left: 8vw;
  }
}
@media screen and (max-width: 1000px) {
  .comicNav {
    position: absolute;
    bottom: 5vh;
    width: 17vw;
    left: 15vw;
    margin-top: 2px;
  }
  // ComicNavBack

  .comicNavBack {
    display: none;
    position: absolute;
    bottom: 5vh;
    left: 6vw;
  }
}
#warning {
  width: 10%;
  z-index: 100;
  top: 25vh;
  right: 20vw;
  visibility: hidden;
}

.badgeContainer {
  position: absolute;
  align-items: center;
  top: 50vh;
  // right: ;
}

.testing {
  color: black;
  border: 2px solid black;
}

.evenmore {
  height: 100%;
}

.achievementBanner {
  position: relative;
  top: 13vh;
  height: 15vh;
}
.badgeImage {
  display: flex;
  flex-direction: row;
  position: relative;
  // height:  inherit
}

.badgeFix {
  visibility: hidden;
  right: -60vw;
  display: flex;
  position: absolute;
  z-index: 11;
  height: 1vh;
  bottom: 20vh;
  // height: 50%;
}
.expertBadge {
  bottom: -25vh;
}

.alert {
  position: absolute;
  width: 30vw;
  left: 60vw;
  z-index: 1;
  visibility: hidden;
}

.stealWrapper {
}

.stacTitleParent {
  z-index: 1;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.stacTitle {
  // border: solid 2px black;
  width: 25vw;
  text-align: center;
  padding: 0.5em;
  font-size: 3.5em;
  font-family: $comicStrip;
  // font-size: 20pt
}
.definition {
  // border: solid 2px black;
  position: relative;
  width: 40vw;
  font-size: 1.25rem;
  font-family: $font1;
  color: white;
  padding: 0.5em;

  // top:-20px;
  border-radius: 10px;
  // text-align: center;
  background-color: $primaryColor;
  box-shadow: 5px 5px black;
}
.introStac {
  height: 80%;
  padding: 30px;
}
.coachFix {
  padding-top: 4vh;
  padding-left: 30px;
}
.stacGraphicTitle {
}

.stac-graphic-wrap {
}

.extraWide {
  width: 40%;
}
.smaller {
  font-size: 2.2vh;
}
@media screen and (max-width: 1000px) {
  .stacGraphicTitle {
  }
}
#turnPage {
  .close {
    background-color: $primaryColor;
    opacity: 1;
    font-family: $comicStrip;
    color: white;
    width: 20vw;
    height: 10vh;
    font-size: 300%;
    border-radius: 20px;
    box-shadow: 5px 5px black;
  }
  .close:focus {
    outline: none;
    // opacity: .5;
  }
  .close:hover {
    opacity: 0.7;
  }
  .text {
    margin-top: 5vh;
    margin-left: auto;
    font-family: $comicStrip;
    font-size: 220%;
    width: 90%;
  }
}

#accompanyPage {
  .close {
    background-color: $primaryColor;
    opacity: 1;
    font-family: $comicStrip;
    color: white;
    width: 20vw;
    height: 10vh;
    font-size: 300%;
    border-radius: 20px;
    box-shadow: 5px 5px black;
  }
  .close:focus {
    outline: none;
    // opacity: .5;
  }
  .close:hover {
    opacity: 0.7;
  }
  .text {
    margin-top: 5vh;
    margin-left: auto;
    font-family: $comicStrip;
    font-size: 220%;
    width: 90%;
  }
}

#coachPage {
  .close {
    background-color: $primaryColor;
    opacity: 1;
    font-family: $comicStrip;
    color: white;
    width: 20vw;
    height: 10vh;
    font-size: 300%;
    border-radius: 20px;
    box-shadow: 5px 5px black;
  }
  .close:focus {
    outline: none;
    // opacity: .5;
  }
  .close:hover {
    opacity: 0.7;
  }
  .text {
    margin-top: 5vh;
    margin-left: auto;
    font-family: $comicStrip;
    font-size: 220%;
    width: 90%;
  }
}

#scene2 {
  .fix {
    z-index: 20;
    height: 70vh;
    width: 65vw;
    left: 5vw;
    top: 1vh;
    text-align: initial;
    white-space: initial;
  }
  .close {
    background-color: $primaryColor;
    opacity: 1;
    font-family: $comicStrip;
    color: white;
    width: 20vw;
    height: 10vh;
    font-size: 300%;
    border-radius: 20px;
    box-shadow: 5px 5px black;
  }
  .close:focus {
    outline: none;
    // opacity: .5;
  }
  .close:hover {
    opacity: 0.7;
  }
  p.text {
    margin-top: 5vh;
    // margin-left: auto;
    font-family: $comicStrip;
    font-size: 220%;
    width: 90%;
    font-weight: initial;
    margin-left: auto;
  }
}

.badgeModal {
  margin-bottom: 2rem;
}

.greyback {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000000c7;
  z-index: 100;
}

.bullet-img-wrap {
  display: flex;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  .img-wrap-portait {
    @media screen and (max-width: 1000px) {
      width: 100%;
    }

    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100px;
    max-width: 250px;
    > img {
      width: 100%;
      border-radius: 10px;
    }
  }

  .img-wrap {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 35%;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
    > img {
      width: 100%;
      border-radius: 10px;
    }
  }
}

.sub-bullets {
  font-size: inherit;
  margin-left: 1rem;
}
