@import '../../../stylesheets/variables';

.subHeader {
  font-size: 1.5rem;
  color: $accentColor;
  margin: 0.5rem 0;
  margin-top: 1rem;
  @media screen and (min-width: 900px) {
    font-size: 1.75rem;
  }
}

.scenario {
  font-size: 1rem;
  @media screen and (min-width: 900px) {
    font-size: 1.25rem;
  }
}

.scenarioWrap {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.sectionWrap {
  margin: 1rem 0;
  @media screen and (min-width: 900px) {
    margin: 2rem 0;
  }
}

.firstItem {
  margin-top: 0;
}

.banner {
  background: #ecf0ed;
  font-weight: 600;
  color: black;
  padding: 1rem 1.5rem;
  border-top: 2px solid gray;
  border-bottom: 2px solid gray;
  margin: 1rem 0;
  font-size: 1rem;
  font-size: 1rem;
  @media screen and (min-width: 900px) {
    font-size: 1.25rem;
  }
}
