@import '_variables';
.bullyTypeImage {
  height: 100%;
  width: 100%;
}
.startScreenLogo {
  background-color: transparent;
  width: 200px;
}
.poweredBy {
  margin: 0 auto;
  align-content: center;
  max-width: 75%;
  width: 500px;
}
.intro1logo {
  font-size: 50%;
  width: 10em;
}
.scenarioPhotos1 {
  background-color: white;
  width: 350px;

  margin-left: 6px;
  margin-right: 6px;
}
.scenarioPhotos2 {
  margin-left: 25px;
  background-color: white;
  width: 350px;
}

#imageContainer {
  flex: 1;
  box-shadow: 5px 5px 10px 6px rgb(0 0 0 / 39%);

  background-color: transparent;
  height: auto;
  border-radius: 15px;
  flex-basis: 300px;
  overflow: hidden;
  min-width: 60%;
  max-width: 500px;
  // @media screen and (max-width: 500px) {
  //   max-width: 300px;
  // }
}

.bystandSketch {
  position: relative;
  left: -60px;
  width: 65%;
  border-radius: 10px;
}

.avatarBox {
  position: absolute;
  bottom: 0px;
  left: 0px;
  text-align: center;
}

.avatar-slide {
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}

.avatar-wrapper {
  gap: 2rem;
  justify-content: center;
  background-color: lightgrey;
  border-radius: 10px;
  padding: 2rem;
}

.av-grid {
  max-width: 650px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }
}
.av-wrap {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
  border: black solid 4px;
  border-radius: 10px;

  box-shadow: 2px 2px 10px 6px rgb(0 0 0 / 39%);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background: white;
  padding: 0;
  &:hover {
    transform: scale(1.05);
  }

  &.selected {
    border: $secondaryColor solid 4px;
    transform: scale(1.1);
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 5px 5px 10px 6px rgb(0 0 0 / 39%);
    &:hover {
      transform: scale(1.1);
    }
  }

  &.flash {
    animation: flash 175ms linear 6 alternate;
  }
}

@keyframes flash {
  from {
    scale: 1;
  }
  to {
    scale: 1.1;
  }
}

.avatar {
  width: 100%;
}

.trainer {
  position: absolute;
  height: 50vh;
  z-index: 1;
}
.comicStrip {
  position: absolute;
  top: 10vh;
  right: 5vw;
  width: 40vw;
  z-index: 0;
}
