@import '_variables';
.intro2Box {
  flex-basis: 350px;
  align-self: center;
  /* width: 40vh; */
  /* position: absolute; */
  bottom: 3em;
  right: 1.4em;
  /* box-shadow: 5px 5px black; */
  /* border-radius: 10px; */
  /* border: solid 2px black; */
}

.intro2 {
  width: 100%;
  padding: 0.7em;
  font-weight: 600;
}

#intro2Bubble {
  position: absolute;
  left: 100px;
}

.learningModule1 {
  position: relative;
  flex-direction: column;
  background-color: transparent;
  flex: 80%;
  left: 5%;
  padding: 20px;
}
#intro5Title {
  font-size: 3vh;
}
#consequencesSelector {
  position: relative;
  top: -15px;
  // top: -2vh;
  // right: 0px;
  // min-height: 500px;
}

.intro5Box {
  flex: 1;
  display: flex;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-basis: 400px;
  max-width: 550px;
}
