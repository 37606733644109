@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;1,700&family=Merriweather:wght@900&display=swap');
$main-font: Helvetica, Arial, sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

$font1: 'Merriweather', serif;
$sansSerifPrimary: 'Roboto', sans-serif;
$chalkFont: 'Fredericka the Great', cursive;
$fontSize: 2vw;
$handWriting: 'Indie Flower', cursive;
$comicStrip: 'Bangers', cursive;
$text: black;

// $background: #6688ca;
// $primaryColor: #0039a6;
// $secondaryColor: #f9461c;
// $primaryColor: #406bbc;
// $generalFontColor: black;
// $titleColor: white;
// $background: lightgrey;
// $speechBubble: lightGrey;

// // FOREST
$background: #ecf0ed;
$primaryColor: #3e85d3;
$accentColor: #3270a6;
$secondaryColor: #f59c52;
$correctColor: #16a34a;
$incorrectColor: #f44336;
$generalFontColor: black;
$titleColor: #ecf0ed;
$speechBubble: lightGrey;

$backgroundColor: white;

// // MARINE
// $background: #B1C0C9;    //ACTIVITY BACKGROUND COLOR
// $primaryColor:#467082 ; //HEADER COLOR
// $secondaryColor: #C26558; //PROGRESS BUTTONS
// $primaryColor: #B1C0C9;     // FOOTER COLOR
// $generalFontColor: black;
// $titleColor: #467082;   //TITLE COLOR
// $speechBubble: lightGrey;
// $background: #B1C0C9;   //GENERAL BACKGROUND
