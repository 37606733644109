@import 'variables';

.popup {
  color: black;
  border: solid 4px white;
  background-color: $secondaryColor;
  color: $background;
  padding: 2rem;
  font-size: 1.25rem;
  overflow: hidden;
  font-family: $font1;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;

  .close {
    color: $secondaryColor;
    opacity: 1;
    background-color: white;
    border-radius: 10px;
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      opacity: 1;
      background: white;
      color: $secondaryColor;
    }
  }
  // left: vw;
}
.greyout {
  position: absolute;
  top: -100px;
  left: 0px;
  width: 100vw;
  background-color: black;
  z-index: 9;
  opacity: 0.3;
  height: 100vh;
  border: solid 2px black;
}

.conclusionPopup {
  width: 90%;
  height: 100%;
  top: 0px;
  left: 5%;
  align-content: center;
  background-color: transparent;
  border: none;
}
.modal3-1 {
  left: 0px;
}
.warning {
  position: absolute;
  top: 10px;
  // z-index: 100;
  right: 0px;
  width: 72%;
}
.warningModal {
  position: absolute;
  bottom: 0px;
  left: 12.5%;
  top: 1000px;
  height: 60vh;
  width: 75%;
  // bottom: 100px;
  border-radius: 10px;
}
.warningButtonContainer {
  position: absolute;
  // right: 0px;
  bottom: 0vh;
  right: 12vw;
}

.warningParent {
  opacity: 0;
}
