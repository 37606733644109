@import '../../../stylesheets/variables';

.start-wrap {
  display: flex;
  align-items: center;
  max-width: 1000px;
  margin: auto;
  justify-content: center;
  gap: 4rem;
  font-style: $sansSerifPrimary;
}

.selection-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > button,
  a {
    width: 200px;
    color: white;
    background: $primaryColor;
    padding: 0.25rem 0.5rem;
    font-size: 1.5rem;
    border-radius: 10px;
    border: 2px solid $accentColor;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  }
}

.startHeader {
  display: flex;
  font-size: 1.5rem;
  text-align: start;
  max-width: 1000px;
  margin-bottom: 1rem;
}

.welcome-card {
  display: flex;
  gap: 2rem;
  align-items: center;

  margin: auto;
  flex-wrap: wrap;

  > .teacherContainer {
    flex: 1 !important;
    > .mainNavBubble {
      margin-top: 2rem;
      position: relative;
      width: 75% !important;
      max-width: none;
      left: 20%;
      bottom: 23px;
    }
  }
}

.long-text {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.wrappingz {
  padding: 1rem;
}

.disabled {
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.lock-wrap {
  position: absolute;
  background: #808080b1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  color: white;
  padding-right: 1rem;
}

.lockme {
  width: 15px;
  fill: white;
  position: relative;
  bottom: 2px;
}

.startLink {
  &:hover {
    text-decoration: none;
    color: black;
  }
}

.startLink-disabled {
  pointer-events: none;
  background: grey;
  color: white;
}

.inStartPAge {
  flex: 1;
  min-width: 250px;
}

.languageToggleContainer {
  display: flex;
  justify-content: end;
}
