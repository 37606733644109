@import 'variables';

.question {
  color: $generalFontColor;
  // background: $primaryColor;
  font-size: 10vh;
  border-radius: 20px;
  height: 7em;
  padding: 50px;
  width: 12em;
  overflow: hidden;
}

.answer {
  background: $primaryColor;
  font-size: 10vh;
  padding: 20px;
  border-radius: 20px;
}

.inputBox {
  color: $generalFontColor;
  border-radius: 5px;
  font-size: 4vh;
  width: 20%;
  padding: 10px;
  background: $background;
  position: absolute;
  bottom: 65px;
  right: 375px;
  box-shadow: 5px 5px black;
  border-radius: 10px;
  border: solid 2px black;
  z-index: 10;
}
.percentageInput {
  background-color: grey;
  color: white;
  // opacity: .4;
  width: 45%;
  border: none;
}
.submitButton {
  position: absolute;
  bottom: 65px;
  right: 200px;
  z-index: 10;
}

#activitySpeechBubble {
  margin-top: -30px;
  margin-left: 30%;
}
#modalBubble {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -10%;
  bottom: 12%;
  opacity: 1;
  overflow: hidden;
}
#ActivityModule {
  #modalBubble {
    .speech-bubble-modal {
      width: 60vw;
      font-size: 80%;
    }
    .speech-bubble-shadow-modal {
      width: 60vw;
    }
    .speech-bubble-modal:after {
      left: 20%;
    }
    .speech-bubble-shadow-modal:after {
      left: 20%;
    }
  }
}
.display-none {
  display: none;
}

.responseBubble {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -9%;
  bottom: 12%;
  z-index: 3;
}

#answerBubble {
  opacity: 0;
}

#descriptionBox {
  font-size: 3vh;
  position: absolute;
  width: 50%;
  right: 5%;
  height: 50%;
  background-color: #252d35;
  box-shadow: 5px 5px black;
  border: solid #d5a87f 7px;
  border-radius: 10px;
  font-family: $chalkFont;
}

.descriptionBox {
  @media screen and (max-width: 750px) {
    font-size: 1rem;
  }
  font-size: 1.25rem;
  right: 5%;
  height: 50%;
  background-color: white;
  box-shadow: 5px 5px 10px 6px #00000063;
  border: solid #3a3a3a 7px;
  border-radius: 10px;
  overflow: auto;
  padding: 15px;
  color: $accentColor;
  font-family: 'Itim', cursive;
  padding-left: 20px;
  font-weight: 400;
  flex: 1;
  min-width: 300px;

  > ul {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  &.wide {
    width: 100%;
  }
  li {
    font-size: 1rem;
    @media screen and (min-width: 750px) {
      font-size: 1.25rem;
    }
    color: $accentColor;
    font-family: 'Itim', cursive;
  }
}

.type-of-bullies-wrap {
  flex-wrap: wrap-reverse;
  display: flex;
  justify-content: center;
  align-items: start;
  /* margin: 1rem; */
  gap: 2rem;

  .imageContainer {
  }
}

.descriptionBoxMain {
  padding-left: 20px;
  position: absolute;
  width: 50%;
  right: 0%;
  left: 37vw;
  height: 60vh;
  background-color: #252d35;
  box-shadow: 5px 5px black;
  border: solid #d5a87f 7px;
  border-radius: 10px;
  color: white;
  font-family: $chalkFont;
  overflow: auto;
  font-size: 20%;
}

#reinforcersSlide {
  .descriptionBoxMain .chalkboard {
    li {
      padding: 2px;
    }
    font-weight: 570;
    font-size: 200%;
    padding: 0 10 10;
  }
}
#outsidersSlide {
  .descriptionBoxMain .chalkboard {
    li {
      padding: 2px;
      font-size: 90%;
    }
    font-weight: 570;
    padding: 0 10 10;
  }
}

#defendersSlide {
  .descriptionBoxMain .chalkboard {
    li {
      padding: 2px;
      font-size: 90%;
    }
    font-weight: 570;
    padding: 0 10 10;
  }
}

.descriptionBoxStac {
  background: white;
  margin-top: 2rem;
  margin: auto;
  margin-top: 1.5rem;
  border: black solid 7px;
  margin-bottom: 1rem;
  padding-left: 20px;
  padding-top: 0vh;
  /* width: 95%; */
  right: 2.5%;
  bottom: 0%;
  height: 70%;
  /* background-color: #252d35; */
  box-shadow: 5px 5px 10px 6px rgb(0 0 0 / 39%);
  border-radius: 10px;
  color: white;
  font-family: 'Fredericka the Great', cursive;
  vertical-align: middle;
  overflow: auto;
}
#stealPage {
  background: #ef3340;

  border-radius: 10px;
  @media screen and (max-width: 750px) {
    padding: 1rem;
  }
  padding: 2rem;

  .steal-title-wrap {
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    margin-bottom: 1rem;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .definition {
    flex: 1;
    background: white;
    color: black;
    min-width: 70%;
  }

  .stac-graphic-wrap {
    max-width: 200px;

    img {
      width: 100%;
    }
  }

  .descriptionBoxStac {
    .chalkboard {
      font-size: 40%;
      li {
        font-size: 90%;
        padding: 0px;
        line-height: 20px;
      }
    }
  }
}
#turnPage {
  .descriptionBoxStac {
    padding-top: 10px;
    .chalkboard {
      font-size: 60%;
      li {
        padding: 5px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #stealPage {
    .descriptionBoxStac {
      .chalkboard {
        font-size: 48%;
        li {
          padding: 5px;
          padding-top: 20px;
        }
      }
    }
  }
}

#descriptionBoxSecond {
  .chalkboard {
    font-size: 70%;
  }
  padding-left: 20px;
  position: absolute;
  width: 65%;
  right: 0%;
  top: 95%;
  left: -7%;
  max-height: 175px;
  // height:20vh;
  background-color: #252d35;
  box-shadow: 5px 5px black;
  border: solid #d5a87f 7px;
  border-radius: 10px;
  color: white;
  font-family: $chalkFont;
  overflow: auto;
}
@media screen and (max-width: 1000px) {
  #descriptionBoxSecond {
    // background-color: black;
    height: 30vh;
  }
}

.teacherActivityImage {
  position: absolute;
  height: 300px;
  left: -1%;
  bottom: 5px;
}
#answerGraph {
  opacity: 0;
  font-size: 4vh;
  position: absolute;
  width: 50%;
  right: 5%;
  height: 50%;
  background-color: #252d35;
  box-shadow: 5px 5px black;
  border-radius: 10px;
  font-family: $font1;
}

// @media screen and (max-height: )

@media screen and (max-width: 1500px) and (min-height: 1400px) {
  .descriptionBox {
    font-size: 1.75vw;
  }
}

@media screen and (max-width: 875px) {
}
@media screen and (max-width: 750px) {
}
